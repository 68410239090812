import React from "react";
import Step from "./Step";
const Screening = ({ screeningProcess }) => {
  return (
    <div className="bg-[#EDF0F5] min-h-screen relative py-24 h-full mt-[10rem] md:my-0">
      <img
        src="/img/network-bg.png"
        className="absolute top-0 z-10 max-md:h-screen object-cover w-[90%]"
      />
      <div className="container mx-auto">
        <p className="text-black font-mont font-bold text-center text-2xl md:text-[38px] mx-2 mb-4">
          {screeningProcess?.heading}
        </p>

        <div className="flex items-center my-24 max-w-5xl mx-auto hidden md:flex">
          <div className="flex-1">
            <img src="/img/screening.png" alt="" />
          </div>
          <div className="flex-[2] flex flex-col gap-5">
            {screeningProcess?.steps?.map((step, index) => (
              <Step
                key={index}
                step={step}
                marginRight={
                  index === 1 || index === 3
                    ? "7%"
                    : index === 2
                    ? "13%"
                    : "0px"
                }
              />
            ))}
          </div>
        </div>
        <div className="flex gap-6 my-12 max-w-full mx-4 sm:hidden">
          <img src="/img/Line.png" alt="" />
          <div className="flex flex-col gap-20 mt-20">
            {screeningProcess?.steps?.map((step, index) => (
              <div key={index} className="flex flex-col gap-2">
                <img
                  src="/img/circle-icon.png"
                  className="w-[28px] h-[28px]  absolute left-[1px]"
                  alt=""
                />
                <p className="text-black font-mont font-bold text-xl">
                  16.5%
                  <br />
                  <small>pass rate</small>
                </p>
                <Step key={index} step={step} marginRight="0px" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screening;
