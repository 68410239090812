import React from "react";
import { ArrowForward } from "../Icons";
const FindMore = () => {
  return (
    <div className="bg-[#919EAB33] border border-dashed border-[#919EAB] p-3 rounded-[16px] max-w-[411px] max-md:min-h-[350px] w-full flex justify-center items-center">
      <div>
        <p className="text-white font-mont text-[48px] font-bold uppercase leading-[45px] text-center">
          Find more specialists
        </p>

        <p className="text-[#004CFF] uppercase font-sanpro text-center font-medium flex justify-center">
          let’s do it <ArrowForward />
        </p>
      </div>
    </div>
  );
};

export default FindMore;
