import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Back, Forward } from "../Icons";
import "swiper/swiper.scss";
import SwiperCore, { Controller } from "swiper";

SwiperCore.use([Controller]);

const Why = ({ whyBA }) => {
  const [mainSwiper, setMainSwiper] = useState(null);
  const [thumbSwiper, setThumbSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (mainSwiper && thumbSwiper) {
      thumbSwiper.slideTo(activeSlide);
    }
  }, [activeSlide, mainSwiper, thumbSwiper]);

  useEffect(() => {
    if (mainSwiper && thumbSwiper) {
      mainSwiper.on("slideChange", () => {
        setActiveSlide(mainSwiper.realIndex);
      });
    }
  }, [mainSwiper, thumbSwiper]);

  const handleThumbClick = (index) => {
    mainSwiper && mainSwiper.slideTo(index);
  };

  return (
    <div
      className="py-12 px-5 flex flex-col items-center justify-end md:justify-center mt-8 mb-[2rem] md:mb-0"
      style={{
        backgroundImage: 'url("/img/why.png")',
      }}
    >
      <h1 className="font-mont text-2xl md:text-4xl font-bold text-center">
        {whyBA?.heading}
      </h1>
      <div className="flex justify-center py-12 relative max-w-[750px] mx-auto h-full w-full md:w-[750px]">
        <img
          src="/img/why-img.png"
          alt=""
          className="absolute h-full w-full"
        />
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop
          pagination={false}
          navigation={false}
          className="p-4"
          onSwiper={setMainSwiper}
        >
          {whyBA?.steps?.map((step, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center justify-center min-h-[300px] py-4 px-4 mt-5">
                <div>
                  <img
                    src="/img/why-icon.png"
                    alt=""
                    className="max-w-[67px]"
                  />
                  <p className="text-white text-[24px] font-[600] font-mont max-w-[600px] py-4">
                    {step?.review}
                  </p>
                  <p className="font-sanpro text-[#ffffffc2]">
                    {step.author}
                  </p>
                </div>
              </div>
              <div className="ml-4 md:ml-10 flex items-center gap-4 cursor-pointer">
                <img src="/img/play-icon.png" alt="" />
                <p className="text-white uppercase font-mont font-[600]">
                  watch the video
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="flex justify-between gap-2 mt-16 w-full md:max-w-[750px] mx-auto py-4 h-[90px] why">
        <div
          className="cursor-pointer bg-white rounded-[10px] min-w-[66px] h-[60px] flex justify-center items-center hidden md:flex"
          style={{
            boxShadow:
              "0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)",
          }}
          role="button"
          tabIndex="0"
          onClick={() => mainSwiper && mainSwiper.slidePrev()}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              mainSwiper.slidePrev();
            }
          }}
        >
          <Back />
        </div>
        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          slidesPerGroup={1}
          loop
          pagination={false}
          navigation={false}
          className="h-[90px]"
          onSwiper={setThumbSwiper}
        >
          {whyBA?.steps.map((step, index) => (
            <SwiperSlide key={index}>
              <div
                className={`bg-white rounded-[10px] h-[60px] flex justify-center items-center cursor-pointer`}
                role="button"
                tabIndex="0"
                onClick={() => handleThumbClick(index)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleThumbClick(index);
                  }
                }}
              >
                <p
                  style={{
                    boxShadow:
                      "0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)",
                  }}
                  className={`font-mont text-sm bg-white rounded-[10px] h-[60px] flex justify-center items-center px-4 w-full ${
                    thumbSwiper?.activeIndex === index
                      ? "border border-[#004CFF]"
                      : ""
                  }`}
                >
                  {step?.companyName}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="cursor-pointer bg-white rounded-[10px] min-w-[66px] h-[60px]  justify-center items-center hidden md:flex"
          style={{
            boxShadow:
              "0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)",
          }}
          role="button"
          tabIndex="0"
          onClick={() => mainSwiper && mainSwiper.slideNext()}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              mainSwiper.slideNext();
            }
          }}
        >
          <Forward />
        </div>
      </div>
    </div>
  );
};

export default Why;
