import React, { useState } from "react";
import Card from "./Card";
import FindMore from "./FindMore";
import { navigate } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";

const Network = ({ talents, join }) => {
  const [swiper, updateSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveSlide(key);
  };

  return (
    <div className="bg-[#919EAB33] min-h-screen">
      <div className="relative w-full -mt-48 md:-mt-24 p-5 mx-auto">
        <img
          src="/img/network-bg.png"
          className="absolute top-0 -z-10 w-[90%] max-md:h-screen object-cover "
        />
        <p className="text-white font-mont font-bold text-center text-2xl md:text-4xl">
          Our Exclusive Network of Cross-Functional Digital Business
          Analysts
        </p>
        <div className="container mx-auto mt-8">
          <div className="flex-wrap gap-4 hidden sm:flex justify-center">
            {talents?.map((talent, i) => (
              <Card data={talent} key={i} />
            ))}
            <FindMore />
          </div>

          <div className="sm:hidden">
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              slidesPerGroup={1}
              loop
              loopFillGroupWithBlank
              pagination={false}
              navigation={false}
              onSwiper={updateSwiper}
            >
              {talents?.map((v, i) => (
                <SwiperSlide key={i}>
                  <Card data={v} />
                </SwiperSlide>
              ))}
              <FindMore />
            </Swiper>
          </div>

          <div className="flex flex-col justify-center items-center gap-5 max-w-xl mx-auto py-16 ">
            <p className="font-mont text-4xl font-bold leading-10 text-center">
              {join?.heading}
            </p>
            <p className="text-[#181B20] font-sanpro text-center text-lg">
              {join?.description}
            </p>
            <button
              onClick={() => navigate(join?.buttonLink)}
              className="bg-[#EB175C] px-8 py-2.5 rounded-[8px] bg-pink text-white text-center font-mont text-sm md:text-base font-bold leading-none uppercase max-w-sm"
            >
              {join?.buttonName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;
