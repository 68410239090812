import React, { useState } from "react";
import {
  EngagementIcon,
  HirringIcon,
  MatcherIcon,
  ScaleIcon,
  UserIcon,
} from "../Icons";
import { Swiper, SwiperSlide } from "swiper/react";

const Hire = ({ data }) => {
  const [swiper, updateSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveSlide(key);
  };

  return (
    <div className="py-0 md:py-12 my-12">
      <p className="font-mont font-bold text-center text-2xl md:text-4xl py-12 my-12">
        {data?.mainHeading}
      </p>
      <div className="container mx-auto p-4 py-4">
        <div className="hidden md:block flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mx-auto">
            {data?.items?.map((item, index) => (
              <HireCard key={index} index={index} item={item} />
            ))}
          </div>
        </div>
        <div className="sm:hidden hire">
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            slidesPerGroup={1}
            loop
            loopFillGroupWithBlank
            pagination={false}
            navigation={false}
            onSwiper={updateSwiper}
          >
            {data?.items?.map((item, index) => (
              <SwiperSlide key={index}>
                <HireCard key={index} index={index} item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <button className="bg-[#004CFF] p-4 rounded-[8px] bg-pink text-white text-center font-mont text-sm md:text-base font-bold leading-none uppercase max-w-sm">
          Hire a top Business Analyst now
        </button>
      </div>
    </div>
  );
};

export default Hire;

const HireCard = ({ index, item }) => {
  const getIcon = (type) => {
    switch (type) {
      case 0:
        return <UserIcon />;
      case 1:
        return <HirringIcon />;
      case 2:
        return <MatcherIcon />;
      case 3:
        return <ScaleIcon />;
      case 4:
        return <EngagementIcon />;
      default:
        return <UserIcon />;
    }
  };
  return (
    <div className="flex flex-col justify-center items-center gap-2 m-4 border rounded-[16px]  shadow-lg px-4 py-12 md:border-none md:shadow-none">
      <div className="w-[116px] h-[116px] bg-[#004cff18] rounded-full flex justify-center items-center">
        {getIcon(index)}
      </div>
      <p className="font-mont font-medium text-center text-[#181B20] mt-[24px] text-[24px] w-[336px] ">
        {item?.title}
      </p>
      <p className="font-sanpro text-center text-[#181B20] mt-[8px] text-[18px] w-[336px] ">
        {item?.description}
      </p>
    </div>
  );
};
