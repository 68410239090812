import React from "react";
import HomeHeader from "./components/HomeHeader";
import Screening from "./components/Screening";
import Hire from "./components/Hire";
import HowItWorks from "./components/HowItWorks";
import Network from "./components/Network";
import Reviews from "./components/Reviews";
import Why from "./components/Why";

const HomeTemplate = ({ data }) => {
  const header = data?.header || {};
  const talent = data?.talent || {};

  return (
    <div>
      <HomeHeader {...header} />
      <Network talents={talent} join={data?.join} />
      <HowItWorks howItWorks={data?.howItWorks} />
      <Hire data={data.hireFast} />
      <Screening screeningProcess={data.screeningProcess} />
      <Reviews info={data.section3} />
      <Why whyBA={data.whyBA} />
    </div>
  );
};

export default HomeTemplate;
