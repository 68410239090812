import React from "react";
import { ArrowForward } from "../Icons";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const Card = ({ data }) => {
  return (
    <div className="bg-white p-3 rounded-[16px] max-w-[411px]">
      <div className="flex flex-col sm:flex-row items-center gap-4">
        <div>
          {/* <img src="/img/emp.png" className="max-md:rounded-full" /> */}
          <GatsbyImage
            image={getImage(data?.image)}
            alt="Profile"
            className="max-md:rounded-full"
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col max-sm:items-center">
            <p className="text-[#1B0076] font-mont font-bold text-xl ">
              {data?.name}
            </p>
            <p className="text-[#919EAB] font-mont font-regular text-base">
              {data.job}
            </p>
          </div>
          <div className="hidden md:flex items-center gap-4 ">
            <p className="text-[#1B0076] bg-[#1B007610] font-mont px-2 py-1 rounded-[4px] text-xs flex justify-center items-center">
              {data.team}
            </p>{" "}
            <p className="text-[#181B20] text-xs font-mont uppercase">
              {data.country}
            </p>
          </div>
        </div>
      </div>
      <div className="py-3">
        <p className="font-sanpro text-[#181b208a]">
          {data?.description}{" "}
          <button className="inline-flex items-center uppercase text-[#004cff] font-medium font-sanpro">
            Show more <ArrowForward />
          </button>
        </p>
      </div>
      <div className="hidden sm:flex flex-wrap gap-2">
        {data?.skills?.map((v, i) => {
          return (
            <div className="px-2 py-1 border rounded-[4px]" key={i}>
              <p className="font-sanpro">{v}</p>
            </div>
          );
        })}
      </div>
      <button className="block sm:hidden font-mont uppercase font-bold text-[#004cff] border-2 border-[#004cff] w-full p-3 rounded-[8px]">
        Details
      </button>
    </div>
  );
};

export default Card;
