import React from "react";
const HowItWorks = ({ howItWorks }) => {
  return (
    <div className="relative bg-[#919EAB33] max-md:bg-[#221b3c] flex items-center h-full py-12">
      <img
        src="/img/work-bg.png"
        className="absolute w-full h-full"
      />
      <div className="relative  container mx-auto flex flex-col justify-center">
        <div className="pt-24 p-4 flex flex-col items-center">
          <h1 className="text-white font-mont text-2xl md:text-4xl font-bold text-center">
            {howItWorks?.heading}
          </h1>
          <div className="flex flex-col md:flex-row flex-wrap gap-10 mt-16 ">
            {howItWorks?.steps?.map((step, index) => (
              <div key={index} className="flex-1 w-full">
                <div className="relative inline-block w-[60px] h-[60px] mb-4 max-w-[300px]">
                  <p className="absolute left-3 top-[-35px] text-white font-sanpro font-bold text-[67px] z-10">
                    {index + 1}
                  </p>
                  <img
                    src="/img/work-num-bg.png"
                    className="absolute bottom-0 left-0 w-[67px] h-[65px] -z-1"
                    alt="number background"
                  />
                </div>
                <p className="text-white text-[24px] font-mont font-medium max-w-[300px] mx-auto mb-2">
                  {step.title}
                </p>
                <p className="text-white text-[18px] font-sanpro max-w-[300px] mt-4 mx-auto">
                  {step?.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
