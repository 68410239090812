import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const Reviews = ({ info }) => {
  const [swiper, updateSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveSlide(key);
  };
  return (
    <div className="relative bg-[#EDF0F5] flex items-center h-full">
      <img
        src="/img/review-bg.png"
        className="absolute w-full h-full"
      />
      <div className="relative z-1 container mx-auto flex flex-col justify-center items-center py-24 md:py-4">
        <div className="p-4 py-[6rem] md:max-w-3xl max-w-full mx-auto">
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            slidesPerGroup={1}
            loop
            loopFillGroupWithBlank
            pagination={false}
            navigation={false}
            onSwiper={updateSwiper}
          >
            {info?.comments.map(({ writer, content }, i) => (
              <SwiperSlide key={i}>
                <div className="py-5">
                  <img
                    src="/img/review-icon.png"
                    alt=""
                    className="max-w-[67px]"
                  />
                  <p className="text-white font-mont font-semibold text-[32px] py-4">
                    {content}
                  </p>
                  <p className="font-sanpro text-[#ffffffc2] leading-normal">
                    {writer}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="text-center">
            {info.comments.map(({ writer, content }, index) => {
              if (index === activeSlide) {
                return (
                  <button
                    className="inline-block p-[5px] bg-[#ffffff] rounded-full mr-2 transition-all"
                    onClick={() => handleChangeActiveKey(index)}
                  />
                );
              }
              return (
                <button
                  className="inline-block p-[4px] bg-[#004CFF] rounded-full mr-2 transition-all"
                  onClick={() => handleChangeActiveKey(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
