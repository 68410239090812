import * as React from "react";

const ArrowForward = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M9.324 17.417a.915.915 0 0 0 .715-.342l4.425-5.5a.917.917 0 0 0-.01-1.162l-4.583-5.5a.916.916 0 1 0-1.408 1.173l4.102 4.924-3.955 4.915a.916.916 0 0 0 .714 1.492Z"
      clipRule="evenodd"
    />
  </svg>
);

const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={71}
    height={73}
    fill="none"
    {...props}
  >
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M34.997 64.758a20.606 20.606 0 0 1-1.99-8.865c0-8.354 4.95-15.55 12.078-18.816-3.473-.951-7.53-1.87-11.585-1.87-3.875 0-8.072.656-11.947 1.97-5.166 1.313-10.01 3.94-14.207 6.895-1.937 1.642-2.906 3.612-2.906 5.91v14.776h30.557Z"
      clipRule="evenodd"
    />
    <path
      fill="#004CFF"
      d="M33.008 32.25c8.16 0 14.776-6.615 14.776-14.775S41.168 2.699 33.008 2.699c-8.161 0-14.777 6.615-14.777 14.776 0 8.16 6.616 14.776 14.777 14.776Z"
    />
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M36.947 55.893c0-9.249 7.498-16.746 16.746-16.746A16.746 16.746 0 0 1 70.44 55.892c0 9.248-7.498 16.746-16.746 16.746-9.25 0-16.747-7.498-16.747-16.746Zm16.293 6.045 7.653-10.047v-.05a1.684 1.684 0 1 0-2.68-2.044l-6.33 8.373-2.73-3.483a1.677 1.677 0 1 0-2.645 2.06l4.086 5.208c.32.405.807.64 1.323.637a1.675 1.675 0 0 0 1.323-.654Z"
      clipRule="evenodd"
    />
  </svg>
);

const HirringIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={71}
    height={75}
    fill="none"
    {...props}
  >
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M9.318.729C3.896.729.5 3.142.5 8.586v48.357c0 8.166 4.594 12.785 12.727 12.785h24.335a20.643 20.643 0 0 1-2.705-4.928h-21.63c-5.422 0-7.818-2.413-7.818-7.857V8.586c0-2.722 1.198-2.929 3.91-2.929h36.363c2.711 0 3.909.207 3.909 2.929V37.24a20.753 20.753 0 0 1 4.909-.51V8.586c0-5.444-3.396-7.857-8.818-7.857H9.318Zm8.364 14.785a2.46 2.46 0 0 0-2.455 2.465 2.46 2.46 0 0 0 2.455 2.464h19.636a2.46 2.46 0 0 0 2.455-2.465 2.46 2.46 0 0 0-2.455-2.464H17.682Zm0 14.786a2.46 2.46 0 0 0-2.455 2.464 2.46 2.46 0 0 0 2.455 2.464h19.636a2.46 2.46 0 0 0 2.455-2.464 2.46 2.46 0 0 0-2.455-2.464H17.682Zm0 14.786a2.46 2.46 0 0 0-2.455 2.464 2.46 2.46 0 0 0 2.455 2.464H27.5a2.46 2.46 0 0 0 2.454-2.464 2.46 2.46 0 0 0-2.454-2.464h-9.818Z"
      clipRule="evenodd"
    />
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M37.441 57.415c0-9.248 7.498-16.746 16.747-16.746a16.747 16.747 0 0 1 16.746 16.746c0 9.249-7.498 16.747-16.746 16.747-9.249 0-16.747-7.498-16.747-16.747Zm16.293 6.046 7.653-10.048v-.05a1.684 1.684 0 1 0-2.68-2.044l-6.33 8.374-2.729-3.484a1.677 1.677 0 1 0-2.646 2.06l4.086 5.208c.32.405.808.64 1.323.637a1.675 1.675 0 0 0 1.323-.654Z"
      clipRule="evenodd"
    />
  </svg>
);

const EngagementIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={92}
    fill="none"
    {...props}
  >
    <path
      stroke="#004CFF"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={4.5}
      d="M17.552 51.728H13a5 5 0 0 1-5-5v-28a5 5 0 0 1 5-5h35a5 5 0 0 1 5 5v12"
    />
    <path
      stroke="#004CFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.5}
      d="M40.5 9.729v8M20.5 9.729v8M8.56 26.729h43.881"
    />
    <path
      fill="#004CFF"
      d="M74.423 36.728H28.647c-2.808 0-5.147 2.212-5.147 5.031v33.49c0 1.368.557 2.677 1.519 3.628a5.176 5.176 0 0 0 3.628 1.498H42.65c-.344 4.875-2.282 7.77-2.376 7.91a2.168 2.168 0 0 0-.17 2.254 2.186 2.186 0 0 0 1.94 1.19h18.982a2.2 2.2 0 0 0 1.94-1.206 2.112 2.112 0 0 0-.171-2.224c-.096-.142-2.038-3.049-2.382-7.924h14.01c2.846 0 5.077-2.334 5.077-5.126v-33.49c0-2.82-2.231-5.03-5.077-5.03ZM57.544 87.41H45.522c.664-1.734 1.303-4.09 1.471-7.035h9.076c.169 2.945.81 5.3 1.475 7.035Z"
    />
    <path
      fill="#F2F6FF"
      fillRule="evenodd"
      d="M38.8 58.962c0-7.05 5.716-12.766 12.767-12.766a12.767 12.767 0 0 1 12.766 12.766c0 7.05-5.716 12.766-12.767 12.766-7.05 0-12.766-5.715-12.766-12.766Zm12.422 4.61 5.834-7.66v-.04a1.284 1.284 0 1 0-2.043-1.557L50.188 60.7l-2.081-2.656a1.278 1.278 0 0 0-2.017 1.57l3.115 3.97a1.277 1.277 0 0 0 2.017-.013Z"
      clipRule="evenodd"
    />
  </svg>
);

const MatcherIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={79}
    fill="none"
    {...props}
  >
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M60.5 33.728c0 2.516-.395 4.94-1.127 7.211a17.923 17.923 0 0 1 3.566 1.947 27.458 27.458 0 0 0 1.561-9.158c0-1.846-.182-3.65-.529-5.394h-4.093c.407 1.732.622 3.538.622 5.394ZM13.916 48.68c.38-.66.909-1.271 1.583-1.834.458-.316.926-.628 1.402-.934A23.39 23.39 0 0 1 13.5 33.728c0-1.856.215-3.662.622-5.394H10.03a27.633 27.633 0 0 0-.529 5.394 27.372 27.372 0 0 0 4.416 14.952Zm8.012-32.983a23.582 23.582 0 0 0-6.25 8.137h-4.344a27.538 27.538 0 0 1 6.719-10.037c.524.131 1.635.572 2.811 1.237.395.224.751.448 1.064.663Zm3.9-2.649A23.396 23.396 0 0 1 37 10.228c4.048 0 7.857 1.024 11.182 2.826.855-.776 1.868-1.433 2.754-1.935.314-.178.636-.35.959-.511A27.371 27.371 0 0 0 37 6.228a27.371 27.371 0 0 0-14.887 4.375c.326.163.651.336.969.516.884.5 1.893 1.155 2.747 1.93Zm27.326 1.986c-.4.226-.76.452-1.074.67a23.58 23.58 0 0 1 6.242 8.13h4.344a27.538 27.538 0 0 0-6.715-10.033c-.53.135-1.632.573-2.797 1.233Z"
      clipRule="evenodd"
    />
    <path
      fill="#004CFF"
      d="M60.855 69.203a14.256 14.256 0 0 1-19.144-2.415 14.245 14.245 0 0 1 10.29-23.433 14.255 14.255 0 0 1 14.736 12.452 14.244 14.244 0 0 1-2.525 10.04l8.276 8.27a2.374 2.374 0 0 1-2.586 3.906 2.375 2.375 0 0 1-.771-.55l-8.276-8.27ZM62.1 57.589a9.497 9.497 0 0 0-9.502-9.498 9.503 9.503 0 0 0-9.502 9.498 9.497 9.497 0 0 0 9.502 9.499 9.503 9.503 0 0 0 9.502-9.499Z"
    />
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M42.375 42.83c-1.593-.32-3.273-.546-4.953-.546-2.665 0-5.55.444-8.215 1.332-3.552.888-6.882 2.663-9.768 4.66-1.332 1.11-1.999 2.442-1.999 3.995v9.988h17.832c-.401-1.49-.615-3.058-.615-4.675 0-6.112 3.053-11.51 7.718-14.754Z"
      clipRule="evenodd"
    />
    <path
      fill="#004CFF"
      d="M37.426 39.62c5.518 0 9.99-4.472 9.99-9.988 0-5.516-4.472-9.988-9.99-9.988s-9.99 4.472-9.99 9.988c0 5.516 4.473 9.987 9.99 9.987ZM23.6 23.794v-5.523c0-.859-.369-1.718-1.105-2.21-1.474-1.226-3.438-2.086-5.402-2.577-1.351-.368-2.947-.736-4.543-.736-1.474 0-3.07.245-4.543.736-1.964.491-3.806 1.473-5.402 2.578-.737.613-1.105 1.35-1.105 2.21v5.522h22.1ZM12.552 11.275a5.524 5.524 0 1 0-5.525-5.523 5.524 5.524 0 0 0 5.525 5.523ZM72.5 23.794v-5.523c0-.859-.368-1.718-1.105-2.21-1.473-1.226-3.438-2.086-5.402-2.577-1.35-.368-2.947-.736-4.543-.736-1.473 0-3.07.245-4.543.736-1.964.491-3.806 1.473-5.402 2.578-.736.613-1.105 1.35-1.105 2.21v5.522h22.1ZM61.453 11.275a5.524 5.524 0 1 0-5.525-5.523 5.524 5.524 0 0 0 5.525 5.523Z"
    />
  </svg>
);

const ScaleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={83}
    height={95}
    fill="none"
    {...props}
  >
    <path
      fill="#004CFF"
      stroke="#F2F6FF"
      strokeWidth={4}
      d="M78.139 92.911h2v-9.82c0-1.714-.722-3.583-2.36-4.727-2.365-1.945-5.404-3.247-8.344-3.985-1.944-.53-4.407-1.108-6.936-1.108-2.27 0-4.715.373-6.99 1.122-3.06.778-5.862 2.291-8.224 3.926l-.073.05-.069.058c-1.44 1.2-2.284 2.781-2.284 4.664v9.82h33.28Z"
    />
    <path
      fill="#004CFF"
      stroke="#F2F6FF"
      strokeWidth={4}
      d="M62.503 75.186c5.424 0 9.82-4.397 9.82-9.82 0-5.424-4.396-9.82-9.82-9.82-5.423 0-9.82 4.396-9.82 9.82 0 5.423 4.397 9.82 9.82 9.82ZM57.139 92.911h2v-9.82c0-1.714-.722-3.583-2.36-4.727-2.365-1.945-5.404-3.247-8.344-3.985-1.944-.53-4.407-1.108-6.936-1.108-2.27 0-4.715.373-6.99 1.122-3.06.778-5.863 2.291-8.224 3.926l-.073.05-.069.058c-1.44 1.2-2.284 2.781-2.284 4.664v9.82h33.28Z"
    />
    <path
      fill="#004CFF"
      stroke="#F2F6FF"
      strokeWidth={4}
      d="M41.503 75.186c5.424 0 9.82-4.397 9.82-9.82 0-5.424-4.396-9.82-9.82-9.82-5.423 0-9.82 4.396-9.82 9.82 0 5.423 4.397 9.82 9.82 9.82ZM36.14 92.911h2v-9.82c0-1.714-.721-3.583-2.359-4.727-2.365-1.945-5.404-3.247-8.345-3.985-1.943-.53-4.406-1.108-6.935-1.108-2.27 0-4.715.373-6.99 1.122-3.06.778-5.863 2.291-8.224 3.926l-.074.05-.068.058c-1.44 1.2-2.284 2.78-2.284 4.664v9.82h33.28Z"
    />
    <path
      fill="#004CFF"
      stroke="#F2F6FF"
      strokeWidth={4}
      d="M20.503 75.186c5.424 0 9.82-4.397 9.82-9.82 0-5.424-4.396-9.82-9.82-9.82-5.423 0-9.82 4.396-9.82 9.82 0 5.423 4.397 9.82 9.82 9.82ZM57.139 39.911h2v-9.82c0-1.714-.722-3.583-2.36-4.727-2.365-1.945-5.404-3.247-8.344-3.985-1.944-.53-4.407-1.108-6.936-1.108-2.27 0-4.715.373-6.99 1.122-3.06.778-5.863 2.291-8.224 3.926l-.073.05-.069.058c-1.44 1.2-2.284 2.78-2.284 4.664v9.82h33.28Z"
    />
    <path
      fill="#004CFF"
      stroke="#F2F6FF"
      strokeWidth={4}
      d="M41.503 22.186c5.424 0 9.82-4.397 9.82-9.82 0-5.424-4.396-9.82-9.82-9.82-5.423 0-9.82 4.396-9.82 9.82 0 5.423 4.397 9.82 9.82 9.82Z"
    />
    <path
      fill="#004CFF"
      fillRule="evenodd"
      d="M41.836 52.546c-.82 0-1.204-.55-1.204-.92V48.79h-2.791c-.333 0-.982-.375-.982-1.24 0-.823.55-1.207.92-1.207h2.85V43.53c0-.332.374-.983 1.237-.983.821 0 1.204.55 1.204.92v2.875h2.807c.332 0 .982.376.982 1.241 0 .822-.55 1.205-.918 1.205h-2.869v2.775c.002.331-.371.984-1.236.984Z"
      clipRule="evenodd"
    />
  </svg>
);

const Back = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#A6A8AB"
      d="M14.69 6.71a.996.996 0 0 1 0 1.41L10.81 12l3.88 3.88a.997.997 0 0 1-1.41 1.41L8.69 12.7a.996.996 0 0 1 0-1.41l4.59-4.59c.38-.38 1.02-.38 1.41.01Z"
    />
  </svg>
);

const Forward = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#A6A8AB"
      d="M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.997.997 0 0 0 1.41 1.41l4.59-4.59a.997.997 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01Z"
    />
  </svg>
);

export {
  ArrowForward,
  UserIcon,
  HirringIcon,
  EngagementIcon,
  MatcherIcon,
  ScaleIcon,
  Back,
  Forward,
};
