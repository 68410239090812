import React from "react";
import ReactMarkdown from "react-markdown";
import { navigate } from "gatsby";

const HomeHeader = ({
  title = "",
  description = "",
  buttonText = "",
  buttonLink,
}) => {
  return (
    <div className="relative">
      <img
        src="/img/hero-bg.png"
        className="absolute top-0 w-full max-md:h-screen object-cover min-h-[110vh]"
      />
      <div className="relative z-1 text-white  h-[100vh] container mx-auto flex flex-col justify-center pb-32 md:pb-14">
        <div className="max-w-3xl flex flex-col gap-5 px-4 md:px-0">
          <ReactMarkdown
            className="text-white font-mont text-2xl md:text-5xl font-bold leading-tight tracking-wider"
            source={title}
          />
          <ReactMarkdown
            className="text-white text-base md:text-lg font-normal leading-130 tracking-[0.54px] font-sanpro max-w-lg"
            source={description}
          />
          <button
            onClick={() => navigate(buttonLink)}
            className="bg-[#EB175C] p-4 rounded-[8px] bg-pink text-white text-center font-mont text-sm md:text-base font-bold leading-none uppercase max-w-sm"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
