import React from "react";
import Layout from "@layouts/Layout";

import HomeTemplate from "./Home";

const HomePage = ({ data: initialData }) => {
  const data = initialData?.markdownRemark?.frontmatter;
  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };
  return (
    <Layout seo={seo} isLanding>
      <HomeTemplate data={data} />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Home" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        whyBA {
          heading
          steps {
            review
            author
            videoLink {
              name
              publicURL
            }
            companyIcon {
              childImageSharp {
                gatsbyImageData
              }
            }
            companyName
          }
        }
        talent {
          name
          job
          team
          skills
          country
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 80
                height: 80
                sizes: "(max-width: 1116px) calc(100vw - 120px), 1116px"
              )
            }
          }
        }
        contact {
          category
          title
          description
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        whoWeAre {
          category
          title
          description
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        section3 {
          comments {
            writer
            content
          }
        }
        screeningProcess {
          mainImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          heading
          steps {
            title
            description
          }
        }
        join {
          heading
          description
          buttonName
          buttonLink
        }
        howItWorks {
          heading
          steps {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 60
                  height: 60
                  sizes: "(max-width: 1116px) calc(100vw - 120px), 1116px"
                )
              }
            }
          }
        }
        hireFast {
          mainHeading
          items {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 80
                  height: 80
                  sizes: "(max-width: 1116px) calc(100vw - 120px), 1116px"
                )
              }
            }
          }
        }
        approach {
          description
          category
          title
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttonLink
        }
        experience {
          category
          title
          description
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video {
            name
            publicURL
          }
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
