import React from "react";

const Step = ({ step, marginRight }) => {
  return (
    <div
      className={`bg-white p-4 rounded-[8px] max-w-[600px]`}
      style={{ marginLeft: marginRight }}
    >
      <div className="flex items-center gap-2">
        <div className="w-[12px] h-[12px] bg-[#004CFF] rounded-full" />
        <p className="text-[18px] font-mont font-medium">
          {" "}
          {step?.title}
        </p>
      </div>
      <p className="font-sanpro text-[#181b2079] text-sm">
        {step?.description}
      </p>
    </div>
  );
};

export default Step;
